import logoUsm from 'shared/icons/logo-usm.webp'
import styles from './Logo.module.scss'

export function Logo() {
  return (
    <a href="/" className={styles.logo}>
      <img src={logoUsm} alt="logo" />
    </a>
  )
}
