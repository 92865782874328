import useAppContext from 'app/store/useAppContext'
import { AffirmationForm } from 'features/affirmation/form/form'
import classNames from 'shared/lib/class-names'
import GiftImg from 'shared/images/gift.webp'
import IdeaImg from 'shared/images/idea.webp'

import styles from 'pages/home/contacts/Contacts.module.scss'

export default function Contacts() {
  const {
    store: { isMobile, user },
    dispatch,
  } = useAppContext()

  const modalTitle = isMobile
    ? 'Оставьте Ваши контакты и начните зарабатывать пассивный доход'
    : 'Оставьте Ваши контакты,\n' + 'чтобы открыть инвест-счет'

  return (
    <section
      className={classNames('container__desktop_outside', styles.contacts)}
      id="contacts"
    >
      <div className={styles.left_block}>
        {!isMobile && <img src={GiftImg} alt="gift" />}
        {isMobile && (
          <div className={styles.gift_mobile}>
            <img src={IdeaImg} alt="gift" />
            <h3>Откройте инвест-счет уже сегодня</h3>
            <h4>
              <span>И получите на счёт</span>
              <br />
              <span>600 000 сум</span>
            </h4>
          </div>
        )}
      </div>
      <div className={styles.right_block}>
        <AffirmationForm user={user} dispatch={dispatch} title={modalTitle} />
      </div>
    </section>
  )
}
