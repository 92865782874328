import { useLocation } from 'react-router-dom'

import {
  setIsCookiesModal,
  setIsPrivacyPolicyModal,
  setModals,
} from 'app/store/actions'
import useIsMobileModal from 'features/modal/use-cases/useIsMobileModal'
import { SubmitAffirmationModal } from 'features/affirmation/SubmitAffirmationModal'
import Layout from 'widgets/_layout'
import Menu from 'widgets/header/menu'
import { ROUTES } from 'shared/config/routes'
import { setAllObjValuesFalse } from 'shared/utils/functions'
import classNames from 'shared/lib/class-names'
import ArrowLeftIcon from 'shared/icons/arrow-left.webp'
import { Logo } from 'shared/ui/logo'

import styles from './Header.module.scss'

export default function Header() {
  const { isMobile, isMobileModal, modals, setIsMobileModal, dispatch } =
    useIsMobileModal()

  const { pathname } = useLocation()

  const isRegisteredPage = pathname === ROUTES.REGISTERED

  const closeAllModals = () => {
    const isAffirmationAndPolicyModalOpened =
      modals.isAffirmationModal &&
      (modals.isCookiesModal || modals.isPrivacyPolicyModal)

    if (isAffirmationAndPolicyModalOpened) {
      dispatch(setIsCookiesModal(false))
      dispatch(setIsPrivacyPolicyModal(false))
    } else {
      const objModalsFalseValues = setAllObjValuesFalse(modals)

      dispatch(setModals(objModalsFalseValues))

      setIsMobileModal(false)
    }
  }

  return (
    <header
      className={classNames(
        styles.header,
        isMobileModal ? styles['header--fixed'] : null,
      )}
    >
      <Layout className={styles.layout}>
        {isMobileModal && (
          <img
            className={styles.header__left_icon}
            src={ArrowLeftIcon}
            alt="arrow-left"
            onClick={closeAllModals}
          />
        )}
        <Logo />
        {!isMobile && !isRegisteredPage && (
          <>
            <Menu />
            <SubmitAffirmationModal btnProps={{ variant: 'variant2' }} />
          </>
        )}
      </Layout>
    </header>
  )
}
