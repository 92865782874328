import useAppContext from 'app/store/useAppContext'
import classNames from 'shared/lib/class-names'
import TruckImg from 'shared/images/truck.webp'
import Table from 'pages/home/investors-results/_ui/Table'

import styles from 'pages/home/investors-results/InvestorsResults.module.scss'

export default function InvestorsResults() {
  const {
    store: { isMobile },
  } = useAppContext()

  return (
    <section
      className={classNames('container__desktop', styles.investors_results)}
    >
      <div className={styles.header}>
        <h3 className={styles['header__title']}>Результаты инвесторов</h3>
        <p className={styles['header__desc']}>
          Информация о выплатах транслируется в приложении в реальном времени
        </p>
      </div>
      <div className={styles.content}>
        {!isMobile && (
          <div className={styles['content__img']}>
            <img src={TruckImg} alt="truck" />
          </div>
        )}
        <div className={styles['content__table']}>
          <Table />
        </div>
      </div>
    </section>
  )
}
