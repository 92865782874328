import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import Header from 'widgets/header'
import Footer from 'widgets/footer'
import useSetUserParams from 'entities/user/use-cases/useSetUserParams'
import useSetUserApiData from 'entities/user/use-cases/useSetUserApiData'
import useSetIsMobileModal from 'features/modal/use-cases/useSetIsMobileModal'
import AcceptCookieModal from 'features/cookie/AcceptCookieModal'
import useIsMobileModal from 'features/modal/use-cases/useIsMobileModal'
import { ROUTES } from 'shared/config/routes'
import classNames from 'shared/lib/class-names'

export const MainLayout = () => {
  const { isMobileModal, isSomeModalsOpened } = useIsMobileModal()
  const { pathname } = useLocation()
  const isRegisteredPage = pathname === ROUTES.REGISTERED

  useSetIsMobileModal()
  useSetUserParams()
  useSetUserApiData()

  return (
    <div className="app">
      <Header />
      <main
        className={classNames(
          'main',
          isMobileModal && isSomeModalsOpened ? 'main--fixed' : null,
        )}
      >
        <Outlet />
      </main>
      {!isRegisteredPage && <Footer />}

      <AcceptCookieModal />
    </div>
  )
}
