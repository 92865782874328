import DocumentsAccordion from 'features/documents/accordion'
import classNames from 'shared/lib/class-names'

import styles from 'pages/home/documents/Documents.module.scss'

export default function Documents() {
  return (
    <section
      className={classNames(
        'container__desktop',
        'flex__col_center',
        styles.documents,
      )}
    >
      <div className={styles.header}>
        <h3 className={styles.header__title}>Наши лицензии и документы</h3>
        <p className={styles.header__desc}>
          USM GOLD лицензирована на торговлю и выпуск ценных бумаг для физ.лиц
        </p>
      </div>
      <div className={styles.content}>
        <DocumentsAccordion className={styles.accordion} />
      </div>
    </section>
  )
}
