import useAppContext from 'app/store/useAppContext'
import classNames from 'shared/lib/class-names'
import Cards from 'pages/home/bonds/_ui/Cards'

import styles from 'pages/home/bonds/Bonds.module.scss'

export default function Bonds() {
  const {
    store: { isMobile },
  } = useAppContext()

  return (
    <section className={classNames('container__desktop', styles.bonds)}>
      <div className={styles.header}>
        <h3 className={styles.header__title}>Выберите свой пакет облигаций</h3>
        {!isMobile && (
          <p className={styles.header__desc}>
            Мы предлагаем нашим инвесторам 3 пакета облигаций. Они отличаются по
            цене и объему выплат. Вы можете выбрать наиболее подходящий в
            зависимости от Вашего бюджета и ожиданий в прибыли
          </p>
        )}
      </div>
      <div className={styles.content}>
        <Cards />
      </div>
    </section>
  )
}
