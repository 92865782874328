import Gold1Img from 'shared/images/gold1.webp'
import Gold2Img from 'shared/images/gold2.webp'
import Gold3Img from 'shared/images/gold3.webp'

interface BondsContentType {
  package: string
  income: string
  desc: string
  price_full: string
  price_discount: string
  image: string
}

export const bondsContent: BondsContentType[] = [
  {
    package: 'СТАНДАРТНЫЙ',
    income: '6 000 000 сум',
    desc: 'Пакет акций из активов концерна “Металлоинвест” (ЛГОК, МГОК, ОЭМК и Рудстрой)',
    price_full: '1 200 000 сум',
    price_discount: '2 200 000 сум',
    image: Gold1Img,
  },
  {
    package: 'СТАНДАРТНЫЙ ПАКЕТ',
    income: '13 000 000 сум',
    desc: 'Дивидендные облигации в дочерние компании USM Gold Holding по разработке золотых жил',
    price_full: '2 600 000 сум',
    price_discount: '3 900 000 сум',
    image: Gold2Img,
  },
  {
    package: 'ОПЫТНЫЙ ИНВЕСТОР',
    income: '25 000 000 сум',
    desc: 'Диверсифицированный пакет акций и бондов “Металлоинвест”, “Удоканская медь” и “USM Gold”',
    price_full: '4 700 000 сум',
    price_discount: '8 200 000 сум',
    image: Gold3Img,
  },
]
