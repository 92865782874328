import useAppContext from 'app/store/useAppContext'
import { SubmitAffirmationModal } from 'features/affirmation/SubmitAffirmationModal'
import { Slider } from 'shared/ui/slider'

import { bondsContent } from 'pages/home/bonds/_model/bonds-content'

import styles from 'pages/home/bonds/_ui/Cards.module.scss'

export default function Cards() {
  const {
    store: { isMobile },
  } = useAppContext()

  const cards = bondsContent.map((card, index) => {
    return (
      <li key={index} className={styles.card}>
        <h4 className={styles.package}>
          <span>{card.package}</span>
        </h4>
        <div className={styles.image}>
          <img src={card.image} alt="" />
        </div>
        <p className={styles.income}>
          {card.income}
          <span> / в месяц</span>
        </p>
        <p className={styles.desc}>{card.desc}</p>
        <div className={styles.price}>
          <p className={styles['price__title']}>Цена пакета: </p>
          <div className={styles['price__values']}>
            <p className={styles['price__discount']}>{card.price_discount}</p>
            <p className={styles['price__full']}>{card.price_full}</p>
          </div>
        </div>
        <SubmitAffirmationModal />
      </li>
    )
  })

  return (
    <>
      {isMobile ? (
        <Slider className={styles.cards_slider}>{cards}</Slider>
      ) : (
        <ul className={styles.cards}>{cards}</ul>
      )}
    </>
  )
}
