import { useNavigate } from 'react-router-dom'

import useAppContext from 'app/store/useAppContext'
import { setIsSuccessAffirmationModal } from 'app/store/actions'
import useReactPixelMetric from 'entities/user/use-cases/useReactPixelMetric'
import { ROUTES } from 'shared/config/routes'
import { Button } from 'shared/ui/button'
import { Modal } from 'shared/ui/modal'
import CheckModalIcon from 'shared/icons/check-modal.webp'

import styles from './AcceptModal.module.scss'

export default function AcceptModal() {
  const {
    store: {
      user: {
        searchParams: { metric },
      },
      modals: { isSuccessAffirmationModal },
    },
    dispatch,
  } = useAppContext()

  const navigate = useNavigate()

  const closeModal = () => {
    dispatch(setIsSuccessAffirmationModal(false))
    navigate(ROUTES.REGISTERED)
  }
  const onAccept = () => {
    closeModal()
  }

  useReactPixelMetric(isSuccessAffirmationModal, metric)

  return (
    <>
      <Modal
        isOpen={isSuccessAffirmationModal}
        onRequestClose={closeModal}
        content={
          <div className={styles.modal__content}>
            <img src={CheckModalIcon} alt="accept" />
            <h2 className={styles.modal__title}>
              Ваша заявка принята в работу
            </h2>
            <p className={styles.modal__desc}>
              Ожидайте звонка помощника в течение рабочего дня
            </p>
            <Button onClick={onAccept}>Продолжить</Button>
          </div>
        }
      />
    </>
  )
}
