import Promotion from 'pages/home/promotion'
import OurAdvantages from 'pages/home/our-advantages'
import InvestorsResults from 'pages/home/investors-results'
import Documents from 'pages/home/documents'
import Bonds from 'pages/home/bonds'
import HovInvest from 'pages/home/how-invest'
import Contacts from 'pages/home/contacts'
import classNames from 'shared/lib/class-names'

import styles from './Home.module.scss'

export default function Home() {
  return (
    <div className={classNames(styles.home)}>
      <Promotion />
      <OurAdvantages />
      <InvestorsResults />
      <Documents />
      <Bonds />
      <HovInvest />
      <Contacts />
    </div>
  )
}
