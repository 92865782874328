import { useEffect, useState } from 'react'

import useAppContext from 'app/store/useAppContext'
import { setUserApi } from 'app/store/actions'
import { fetchUserApi } from './fetch-user-api'

export default function useSetUserApiData() {
  const { dispatch } = useAppContext()

  const [isUserData, setIsUserData] = useState<boolean>(false)

  const onFetchUserApi = async () => {
    const userData = await fetchUserApi()

    if (userData) {
      setIsUserData(true)
      dispatch(setUserApi(userData))
    }
  }

  useEffect(() => {
    if (!isUserData) {
      onFetchUserApi()
    }
  }, [isUserData])
}
