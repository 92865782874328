import React, { useState } from 'react'

import type { DocumentsType } from 'features/documents/_model/documents'

import useAppContext from 'app/store/useAppContext'
import PlusIcon from 'shared/icons/plus.webp'
import MinusIcon from 'shared/icons/minus.webp'
import { documentsContent } from '../_model/documents-content'
import DocumentItem from '../_ui/DocumentItem'

import styles from './Accordion.module.scss'

interface IAccordionProps {
  className?: string
}

export default function DocumentsAccordion({ className }: IAccordionProps) {
  const {
    store: {
      isMobileSmall,
      modals: { documentModalType },
    },
    dispatch,
  } = useAppContext()

  const [openIndex, setOpenIndex] = useState<number | null>(0)

  const toggleAccordion = (index: React.SetStateAction<number | null>) => {
    setOpenIndex(openIndex === index ? null : index)
  }

  return (
    <div className={`${styles.accordion} ${className ?? ''}`}>
      {documentsContent.map((item, index) => (
        <div
          key={index}
          className={styles['accordion__item']}
          onClick={() => toggleAccordion(index)}
        >
          <div className={styles.header}>
            <p className={styles['header__title']}>{item.title}</p>
            <button className={styles['header__btn']}>
              {openIndex === index ? (
                <img src={MinusIcon} alt="minus-icon" />
              ) : (
                <img src={PlusIcon} alt="plus-icon" />
              )}
            </button>
          </div>
          <div
            className={
              openIndex === index
                ? styles['accordion__content--open']
                : styles['accordion__content--closed']
            }
          >
            <DocumentItem
              name={item.name}
              docType={item.docType as keyof DocumentsType}
              icon={item.icon}
              isMobileSmall={isMobileSmall}
              documentModalType={documentModalType}
              dispatch={dispatch}
            />
          </div>
        </div>
      ))}
    </div>
  )
}
