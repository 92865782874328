import useAppContext from 'app/store/useAppContext'
import { SubmitAffirmationModal } from 'features/affirmation/SubmitAffirmationModal'
import classNames from 'shared/lib/class-names'
import HeroRromoImg from 'shared/images/hero-promo.webp'
import MetalloinvestIcon from 'shared/icons/metalloinvest.webp'
import MegafonIcon from 'shared/icons/megafon.webp'
import MailRuIcon from 'shared/icons/mail-ru.webp'
import KommersantIcon from 'shared/icons/kommersant.webp'

import styles from 'pages/home/promotion/Promotion.module.scss'

export default function Promotion() {
  const {
    store: { isMobile },
  } = useAppContext()

  return (
    <section className={classNames('flex__col_center', styles.promotion)}>
      <div
        className={classNames(
          'container__desktop',
          styles.promotion__container,
        )}
      >
        <div className={styles.left_block}>
          <h1 className={styles.title}>
            <p>
              <span>ИНВЕСТИРУЙТЕ</span> ОТ 1 200 000 UZS
            </p>
            <p>
              <span>В ЗОЛОТО</span> И ПОЛУЧАЙТЕ
            </p>
            <p>
              ОТ <span>6 000 000 UZS</span> В МЕСЯЦ
            </p>
          </h1>
          <p className={styles.desc}>
            Приобретайте акции главной золото-добывающей компании Узбекистана
          </p>
          <SubmitAffirmationModal />

          {!isMobile && (
            <div className={styles.partners}>
              <p className={styles['partners__title']}>Наши партнеры</p>
              <div className={styles['partners__icons']}>
                <img src={MetalloinvestIcon} alt="metalloinvest" />
                <img src={MegafonIcon} alt="megafon" />
                <img src={MailRuIcon} alt="mail-ru" />
                <img src={KommersantIcon} alt="kommersant" />
              </div>
            </div>
          )}
        </div>
        <div className={styles.right_block}>
          {!isMobile && <img src={HeroRromoImg} alt="hero-promo" />}
          {isMobile && (
            <div className={styles.desc_mobile}>
              <div>
                <p className={styles['desc_mobile__count']}>528 083</p>
                <p className={styles['desc_mobile__text']}>
                  Клиентов зарегистрировались и инвестируют в USM GOLD
                </p>
              </div>
              <div>
                <p className={styles['desc_mobile__count']}>193 трлн сум</p>
                <p className={styles['desc_mobile__text']}>
                  Выплачено дивидендов за 3 квартал 2023 года
                </p>
              </div>
              <div>
                <p className={styles['desc_mobile__count']}>5 735 290 сум</p>
                <p className={styles['desc_mobile__text']}>
                  Составляет средний ежемесяный доход инвесторов USM GOLD
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
