export type TableContentType = {
  id: number
  name: string
  payout: string
  income: string
}

export const tableContent: TableContentType[] = [
  {
    id: 1,
    name: 'Садулла E.M.',
    payout: '5 346 789.23 UZS',
    income: '6.22%',
  },
  {
    id: 2,
    name: 'Рахмони O.B.',
    payout: '3 812 456.78 UZS',
    income: '2.13%',
  },
  {
    id: 3,
    name: 'Томшат Н.У.',
    payout: '7 123 789.45 UZS',
    income: '3.98%',
  },
  {
    id: 4,
    name: 'Назаров А.М.',
    payout: '2 987 654.32 UZS',
    income: '7.56%',
  },
  {
    id: 5,
    name: 'Ходоят З.Ж.',
    payout: '6 543 210.87 UZS',
    income: '1.81%',
  },
  {
    id: 6,
    name: 'Холмуро А.К.',
    payout: '8 765 432.10 UZS',
    income: '8.14%',
  },
  {
    id: 7,
    name: 'Алишер С.С.',
    payout: '4 567 890.56 UZS',
    income: '1.77%',
  },
  {
    id: 8,
    name: 'Мейрам Д.Д.',
    payout: '2 345 678.90 UZS',
    income: '6.98%',
  },
  {
    id: 9,
    name: 'Абдулова Я.Б.',
    payout: '9 012 345.67 UZS',
    income: '1.78% ',
  },
  {
    id: 10,
    name: 'Сапары Р.О.',
    payout: '3 456 789.01 UZS',
    income: '1.59%',
  },
  {
    id: 11,
    name: 'Калиева Ж.П.',
    payout: '6 789 012.34 UZS',
    income: '9.12%',
  },
  {
    id: 12,
    name: 'Жамила Ш.А.',
    payout: '5 678 901.23 UZS',
    income: '6.22%',
  },
  {
    id: 13,
    name: 'Алиева У.В.',
    payout: '4 321 098.76 UZS',
    income: '7.33%',
  },
  {
    id: 14,
    name: 'Исенова А.Ч.',
    payout: '8 901 234.56 UZS',
    income: '4.91%',
  },
  {
    id: 15,
    name: 'Турлыби Т.Б.',
    payout: '1 234 567.89 UZS',
    income: '8.76%',
  },
  {
    id: 16,
    name: 'Шукрат Д.У.',
    payout: '7 890 123.45 UZS',
    income: '4.22%',
  },
  {
    id: 17,
    name: 'Муканы П.П.',
    payout: '6 789 012.34 UZS',
    income: '5.03%',
  },
  {
    id: 18,
    name: 'Досаны К.К.',
    payout: '2 345 678.90 UZS',
    income: '9.12%',
  },
  {
    id: 19,
    name: 'Шарифа Г.К.',
    payout: '9 012 345.67 UZS',
    income: '6.67%',
  },
  {
    id: 20,
    name: 'Касенов З.Ж.',
    payout: '5 678 901.23 UZS',
    income: '9.45%',
  },
]
