import useAppContext from 'app/store/useAppContext'
import useRandomTableContent from 'pages/home/investors-results/use-cases/useRandomTableContent'

import styles from 'pages/home/investors-results/_ui/Table.module.scss'

export default function Table() {
  const {
    store: { isMobile },
  } = useAppContext()

  const contentCount = isMobile ? 5 : 8
  const randomContent = useRandomTableContent(contentCount)

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th>ИМЯ</th>
          <th className={styles.bordered}>ВЫПЛАТА</th>
          <th>ДОХОД</th>
        </tr>
      </thead>
      <tbody>
        {randomContent.map((row, index) => (
          <tr key={index}>
            <td className={styles.name}>{row.name}</td>
            <td className={styles.payout}>{row.payout}</td>
            <td className={styles.income}>
              <div className={styles['income__value']}>
                <span>&#9650;</span>
                {row.income}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
