import useAppContext from 'app/store/useAppContext'
import classNames from 'shared/lib/class-names'
import Cards from 'pages/home/our-advantages/_ui/Cards'

import styles from 'pages/home/our-advantages/OurAdvantages.module.scss'

export default function OurAdvantages() {
  const {
    store: { isMobile },
  } = useAppContext()

  return (
    <section
      className={classNames(
        'container__desktop_outside',
        'flex__col_center',
        styles.our_advantages,
      )}
      id="ourAdvantages"
    >
      <h2 className={styles.title}>
        {isMobile ? 'Ваши гарантии' : 'Наши преимущества'}
      </h2>
      <Cards />
    </section>
  )
}
