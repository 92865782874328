import React, { ReactNode } from 'react'
import ModalLib from 'react-modal'

import classNames from 'shared/lib/class-names'
import CloseIcon from 'shared/icons/close.webp'

import styles from './Modal.module.scss'

ModalLib.setAppElement('#root')

export interface IModal extends ModalLib.Props {
  titleClass?: string
  contentClass?: string
  isCloseIcon?: boolean
  onCloseIconClick?: () => void
  title?: ReactNode
  isTitle?: boolean
  content?: ReactNode
}

const customModalStyles = {
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'var(--bg-opacity1)',
  },
  content: {
    inset: 'initial',
    margin: '15px',
  },
}

export function Modal(props: IModal) {
  const {
    isCloseIcon = true,
    onCloseIconClick,
    title,
    isTitle,
    titleClass,
    content,
    contentClass,
    onRequestClose,
  } = props

  return (
    <>
      <ModalLib
        {...props}
        style={{
          overlay: {
            ...customModalStyles.overlay,
            ...props?.style?.overlay,
          },
          content: {
            ...customModalStyles.content,
            ...props?.style?.content,
          },
        }}
        onRequestClose={onRequestClose}
      >
        <div className={styles.modal__container}>
          {isCloseIcon && (
            <img
              src={CloseIcon}
              alt="close"
              className={styles.modal__close}
              onClick={onCloseIconClick ?? onRequestClose}
            />
          )}
          {isTitle && (
            <div className={classNames(styles.modal__title, titleClass)}>
              {title}
            </div>
          )}
          <div className={classNames(styles.modal__content, contentClass)}>
            {content}
          </div>
        </div>
      </ModalLib>
    </>
  )
}
