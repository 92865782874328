import { useEffect, useState } from 'react'

import type { ISearchParameters } from 'entities/user/model/user'

import useAppContext from 'app/store/useAppContext'
import { setUserSearchParams } from 'app/store/actions'

export default function useSetUserSearchParams() {
  const search = new URLSearchParams(document.location.search)
  const { dispatch } = useAppContext()

  const [searchParams, setSearchParams] = useState<ISearchParameters>(() => ({
    external_id: search.get('external_id') || '',
    metric: search.get('metric') || '',
    gaid: search.get('gaid') || '',
    sub1: search.get('sub1') || '',
    sub2: search.get('sub2') || '',
    sub3: search.get('sub3') || '',
    sub4: search.get('sub4') || '',
    sub5: search.get('sub5') || '',
    sub6: search.get('sub6') || '',
    sub7: search.get('sub7') || '',
    apps_id: search.get('apps_id') || '',
    devKey: search.get('devKey') || '',
    bundle: search.get('bundle') || '',
    status: search.get('status') || '',
  }))

  useEffect(() => {
    const newSearchParams: ISearchParameters = {
      external_id: search.get('external_id') || '',
      metric: search.get('metric') || '',
      gaid: search.get('gaid') || '',
      sub1: search.get('sub1') || '',
      sub2: search.get('sub2') || '',
      sub3: search.get('sub3') || '',
      sub4: search.get('sub4') || '',
      sub5: search.get('sub5') || '',
      sub6: search.get('sub6') || '',
      sub7: search.get('sub7') || '',
      apps_id: search.get('apps_id') || '',
      devKey: search.get('devKey') || '',
      bundle: search.get('bundle') || '',
      status: search.get('status') || '',
    }

    const isSearchParamsChanged =
      JSON.stringify(searchParams) !== JSON.stringify(newSearchParams)

    if (isSearchParamsChanged) {
      setSearchParams(newSearchParams)
    }
  }, [search, searchParams])

  useEffect(() => {
    dispatch(setUserSearchParams(searchParams))
  }, [searchParams, dispatch])
}
