import CardIcon from 'shared/icons/card.webp'
import GuardIcon from 'shared/icons/guard.webp'
import BriefcaseIcon from 'shared/icons/briefcase.webp'
import CursorIcon from 'shared/icons/cursor.webp'

type CardsContentType = {
  id: number
  icon: string
  title: string
  desc1: string
  desc2: string
}

export const cardsContent: CardsContentType[] = [
  {
    id: 1,
    icon: CardIcon,
    title: 'Официальное открытие счета и консультация наставника',
    desc1:
      'Минимальная сумма пополнения счета - от 1 200 000 сум. При открытии счета Вы получите доступ к защищенной торговой платформе. Для Вас будет открыт официальный лицевой счет.',
    desc2:
      'После открытия счета с Вами свяжется Ваш куратор. Он передаст инструкции для старта и поможет защитить аккаунт.',
  },
  {
    id: 2,
    icon: GuardIcon,
    title: 'Полное возмещение депозита за счет государства в случае сбоя',
    desc1:
      'Инвестируя Вы гарантировано сохраняете вложенную Вами сумму (Закон Республики Узбекистан от 7 июля 2006 года № 169-III)',
    desc2:
      'Если возникнет кризис на бирже или в Узбекистане случится инфляция, вы получите свои сбережения назад в долларовом эквиваленте.',
  },
  {
    id: 3,
    icon: BriefcaseIcon,
    title: 'Стабильный источник дивидендов на бирже',
    desc1:
      'Инвестиции в золото, железо и медь гарантировано обезопасят Вас от резких падений курса на рынке ценных бумаг. Вкладывая в природные ресурсы, вы не рискуете своими доходами.',
    desc2:
      'За два года цена золота выросла на 158,3% - в долгосрочной перспективе каждый инвестор выходит в плюс.',
  },
  {
    id: 4,
    icon: CursorIcon,
    title: 'Доступный вход и защита от падения курса сума',
    desc1:
      'Для начала торговли ценными бумагами Вам не требуется много денег. До конца месяца Вы можете открыть депозит всего от 1 200 000 сум.',
    desc2:
      'В мировом эквиваленте это 100$. На Вашем счету будут только долларовые активы, и в случае падения курса сум Вы ничего не потеряете.',
  },
]
