import classNames from 'shared/lib/class-names'

import styles from './HovInvest.module.scss'

export default function HovInvest() {
  return (
    <section
      className={classNames('flex__col_center', styles.hov_invest)}
      id="hovInvest"
    >
      <div
        className={classNames(
          'container__desktop',
          styles.hov_invest__container,
        )}
      >
        <div className={styles.header}>
          <h3 className={styles['header__title']}>
            Как начать инвестировать в облигации?
          </h3>
          <p className={styles['header__desc']}>
            Чтобы получать пассивный доход в 6 000 000 сум от продажи облигаций,
            Вам достаточно пройти всего четыре простых шага
          </p>
        </div>
        <div className={styles.content}>
          <div className={styles.item}>
            <div className={styles['item__num']}>
              <p>1</p>
            </div>
            <p className={styles['item__desc']}>
              Заполните заявку <br />
              для <br />
              регистрации счета
            </p>
          </div>
          <div className={styles.item}>
            <div className={styles['item__num']}>
              <p>2</p>
            </div>
            <p className={styles['item__desc']}>
              Дождитесь звонка <br />
              личного <br />
              консультанта
            </p>
          </div>
          <div className={styles.item}>
            <div className={styles['item__num']}>
              <p>3</p>
            </div>
            <p className={styles['item__desc']}>
              Пополните личный счет <br />
              на сумму <br />
              от 1 200 000 сум
            </p>
          </div>
          <div className={styles.item}>
            <div className={styles['item__num']}>
              <p>4</p>
            </div>
            <p className={styles['item__desc']}>
              Торгуйте и получайте от <br />
              6 000 000 сум <br />
              каждый месяц
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}
