import useAppContext from 'app/store/useAppContext'
import classNames from 'shared/lib/class-names'
import { Slider } from 'shared/ui/slider'

import { cardsContent } from 'pages/home/our-advantages/_model/cards-content'

import styles from 'pages/home/our-advantages/_ui/Cards.module.scss'

export default function Cards() {
  const {
    store: { isMobile },
  } = useAppContext()

  const cards = cardsContent.map((card) => {
    return (
      <li key={card.id} className={classNames(styles.card)}>
        <div className={styles.card__img}>
          <img src={card.icon} alt={`card-${card.id}`} />
        </div>
        <h3 className={styles.card__title}>{card.title}</h3>
        <div className={styles.card__desc}>
          <p>{card.desc1}</p>
          <p>{card.desc2}</p>
        </div>
      </li>
    )
  })

  return (
    <>
      {isMobile ? (
        <Slider className={styles.cards_slider}>{cards}</Slider>
      ) : (
        <ul className={styles.cards}>{cards}</ul>
      )}
    </>
  )
}
