type resultsDataType = {
  id: number;
  fullName: string;
  transactionNumber: string;
  transactionValue: string;
  transactionTime: string;
};

const data: resultsDataType[] = [
  {
    id: 1,
    fullName: "Исмаилов А.Ф.",
    transactionNumber: "Дивиденды ***7321",
    transactionValue: "+1 420 000 UZS",
    transactionTime: "09:18",
  },
  {
    id: 2,
    fullName: "Турсунов Ш.М.",
    transactionNumber: "Дивиденды ***4956",
    transactionValue: "+2 550 000 UZS",
    transactionTime: "14:37",
  },
  {
    id: 3,
    fullName: "Каримов А.А.",
    transactionNumber: "Дивиденды ***1685",
    transactionValue: "+2 650 000 UZS",
    transactionTime: "10:45",
  },
  {
    id: 4,
    fullName: "Алиев Ш.Х.",
    transactionNumber: "Дивиденды ***2598",
    transactionValue: "+5 480 000 UZS",
    transactionTime: "16:20",
  },
  {
    id: 5,
    fullName: "Султанов Н.М.",
    transactionNumber: "Дивиденды ***6043",
    transactionValue: "+6 750 000 UZS",
    transactionTime: "12:03",
  },
  {
    id: 6,
    fullName: "Усманов Р.С.",
    transactionNumber: "Дивиденды ***7489",
    transactionValue: "+8 580 000 UZS",
    transactionTime: "11:29",
  },
  {
    id: 7,
    fullName: "Жамшидов Х.Ш.",
    transactionNumber: "Дивиденды ***3712",
    transactionValue: "+3 900 000 UZS",
    transactionTime: "15:45",
  },
  {
    id: 8,
    fullName: "Назаров А.У.",
    transactionNumber: "Дивиденды ***8260",
    transactionValue: "+3 470 000 UZS",
    transactionTime: "13:10",
  },
  {
    id: 9,
    fullName: "Тошматов О.Р.",
    transactionNumber: "Дивиденды ***5137",
    transactionValue: "+4 720 000 UZS",
    transactionTime: "08:57",
  },
  {
    id: 10,
    fullName: "Рахматуллаев М.Т.",
    transactionNumber: "Дивиденды ***1956",
    transactionValue: "+7 620 000 UZS",
    transactionTime: "17:12",
  },
  {
    id: 11,
    fullName: "Шарипов Д.А.",
    transactionNumber: "Дивиденды ***6743",
    transactionValue: "+5 530 000 UZS",
    transactionTime: "14:02",
  },
  {
    id: 12,
    fullName: "Ибрагимов С.И.",
    transactionNumber: "Дивиденды ***3621",
    transactionValue: "+6 490 000 UZS",
    transactionTime: "10:35",
  },
  {
    id: 13,
    fullName: "Умаров Ж.Р.",
    transactionNumber: "Дивиденды ***8074",
    transactionValue: "+2 840 000 UZS",
    transactionTime: "12:46",
  },
  {
    id: 14,
    fullName: "Хакимов Б.О.",
    transactionNumber: "Дивиденды ***4298",
    transactionValue: "+1 600 000 UZS",
    transactionTime: "16:58",
  },
  {
    id: 15,
    fullName: "Абдуллаев Д.Н.",
    transactionNumber: "Дивиденды ***7512",
    transactionValue: "+5 470 000 UZS",
    transactionTime: "09:23",
  },
  {
    id: 16,
    fullName: "Мирзаев Ш.Р.",
    transactionNumber: "Дивиденды ***5864",
    transactionValue: "+7 780 000 UZS",
    transactionTime: "11:55",
  },
  {
    id: 17,
    fullName: "Садуллаев Н.Ф.",
    transactionNumber: "Дивиденды ***9430",
    transactionValue: "+6 520 000 UZS",
    transactionTime: "15:14",
  },
  {
    id: 18,
    fullName: "Рустамов У.Х.",
    transactionNumber: "Дивиденды ***3102",
    transactionValue: "+5 690 000 UZS",
    transactionTime: "13:28",
  },
  {
    id: 19,
    fullName: "Якубов З.М.",
    transactionNumber: "Дивиденды ***1786",
    transactionValue: "+4 440 000 UZS",
    transactionTime: "17:35",
  },
  {
    id: 20,
    fullName: "Кудратов А.Р.",
    transactionNumber: "Дивиденды ***6975",
    transactionValue: "+4 800 000 UZS",
    transactionTime: "08:42",
  },
];

export default data;
