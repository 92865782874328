import { ReactNode } from 'react'

import MfcaImg from 'shared/images/docs/mfca.webp'
import TradeLicenseImg from 'shared/images/docs/trade-license.webp'
import IssueLicenseImg from 'shared/images/docs/issue-license.webp'
import BankLicenseImg from 'shared/images/docs/bank-license.webp'

export type DocumentsType = {
  mfca: ReactNode
  trade_license: ReactNode
  issue_license: ReactNode
  bank_license: ReactNode
}

export const documents: DocumentsType = {
  mfca: MfcaImg,
  trade_license: TradeLicenseImg,
  issue_license: IssueLicenseImg,
  bank_license: BankLicenseImg,
}
