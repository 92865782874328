import { IFormInputs } from '../_model/IFormInputs'

export const generateEmail = (data: IFormInputs) => {
  let uniqueNumbers = ''
  for (let i = 0; i < 4; i++) {
    uniqueNumbers += Math.floor(Math.random() * 10)
  }
  let genEmail = `genmail1usm${uniqueNumbers}@gmail.com`
  const eneteredEmail = data.email
  const regex = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  )
  if (!eneteredEmail.match(regex)) {
    return genEmail
  }
  return data?.email
}
