import { useEffect, useState } from 'react'

import { getRandomObjects } from 'shared/utils/functions'
import { tableContent } from 'pages/home/investors-results/_model/table-content'

export default function useRandomTableContent(count: number) {
  const [state, setState] = useState<any[]>([])

  useEffect(() => {
    const intervalId = setInterval(() => {
      const content: any[] = getRandomObjects(tableContent, count)
      setState(content)
    }, 2000)

    return () => clearInterval(intervalId)
  }, [count])

  return state
}
