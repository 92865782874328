import * as yup from 'yup'

const schema = yup.object().shape({
  firstName: yup
    .string()
    .required('Укажите Имя')
    .min(2, 'Имя должно содержать более 2 букв')
    .max(40, 'Имя должно быть менее 40 букв')
    .test(
      'test-specialCharacter',
      'Имя не должно специальные символы',
      function (firstName) {
        const regex = new RegExp(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/)
        return regex.test(firstName) === true ? false : true
      },
    )
    .test(
      'test-numbers',
      'Имя не должно содержать цифры',
      function (firstName) {
        const regex = new RegExp('[0-9]')
        return regex.test(firstName) === true ? false : true
      },
    ),

  lastName: yup
    .string()
    .required('Укажите Фамилию')
    .min(2, 'Имя должно содержать более 2 букв')
    .max(40, 'Имя должно быть менее 40 букв')
    .test(
      'test-specialCharacter',
      'Имя не должно содержать специальные символы',
      function (lastName) {
        const regex = new RegExp(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/)
        return regex.test(lastName) === true ? false : true
      },
    )
    .test('test-numbers', 'Имя не должно содержать цифры', function (lastName) {
      const regex = new RegExp('[0-9]')
      return regex.test(lastName) === true ? false : true
    }),
  phoneNumber: yup
    .string()
    .matches(
      /\+998\s?\(?[378]{2}|(9[013-57-9])\)?\s?\d\d\d\s?\d\d\s?\d\d/,
      'Проверьте код мобильного оператора!\n Доступные коды операторов: \n 33, 55, 77, 88, 90, 91, 93, 94, 95, 97, 98, 99 \n Пример: +998 (XX) XXX XX XX',
    )
    .required('Укажите номер телефона'),

  email: yup.string(),
  callHours: yup.string(),
  whatsapp: yup.boolean().default(true),
  telegram: yup.boolean().default(false),
  viber: yup.boolean().default(false),
  isPolitics: yup.boolean().default(true),
  isAge: yup.boolean().default(true),
  country: yup.string(),
  ip: yup.string(),
  external_id: yup.string(),
  metric: yup.string(),
  gaid: yup.string(),
  sub1: yup.string(),
  sub2: yup.string(),
  sub3: yup.string(),
  sub4: yup.string(),
  sub5: yup.string(),
  sub6: yup.string(),
  sub7: yup.string(),
  apps_id: yup.string(),
  devKey: yup.string(),
  bundle: yup.string(),
  status: yup.string(),
  answers: yup.string(),
})

export default schema
