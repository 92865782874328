import { useEffect } from 'react'

export default function useReactPixelMetric(
  isSuccessAffirmationModal: boolean,
  metric: string | null,
) {
  useEffect(() => {
    if (isSuccessAffirmationModal) {
      const ReactPixel = require('react-facebook-pixel')
      ReactPixel.default.init(metric ? metric.toString() : '')
      ReactPixel.default.track('Lead')
    }
  }, [isSuccessAffirmationModal])
}
